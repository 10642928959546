import React from 'react';
import './work.css';
import { Link } from 'gatsby';
import Img from '../image';

function Work({ imageFile, name, link }) {
  return (
    <Link className="ai-work" to={link}>
      <Img
        clickDisabled
        className="ai-work__image"
        fluid={imageFile.childImageSharp.fluid}
        alt={name}
      />
      <div className="ai-work__name">{name}</div>
    </Link>
  );
}

export default Work;
