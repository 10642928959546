import React from 'react';
import './works.css';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Work from '../../components/work/work';

function Works() {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: { name: { regex: "/(Monstercat)|(RIOT)/" }, sourceInstanceName: { eq: "general" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
                aspectRatio
                base64
                sizes
                src
                srcSet
                originalName
              }
            }
          }
        }
      }
    }
  `);
  const images = data.allFile.edges.reduce(
    (obj, item) =>
      Object.assign(obj, { [item.node.childImageSharp.fluid.originalName]: item.node }),
    {},
  );
  return (
    <Layout>
      <SEO title="Monstechcat" />
      <div className="ai-works ai-gap--md">
        <div className="ai-gap--md ai-link-font">
          <h1 className="ai-works-title">Monstechcat</h1>
          <h2 className="ai-works-subtitle">A whole Monstercat project by myself</h2>
        </div>
        <div className="ai-gap--md">
          <div className="ai-link-font">Split in two parts</div>
          <div className="ai-works-list">
            <Work
              name="Monstercat Uncaged Vol. X"
              imageFile={images['Monstercat.jpg']}
              link="/monstechcat/uncaged-vol-x"
            />
            <Work
              name="RIOT - Dogma Resistance LP"
              imageFile={images['RIOT.jpg']}
              link="/monstechcat/riot-dogma-resistance"
            />
          </div>
        </div>
        <div className="ai-padding--vertical-md">
          <div className="container">
            <div className="row">
              <div className="col-xs-10 col-xs-offset-1 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 ai-gap--md ai-works--description">
                <h2 className="ai-works--description">DESCRIPTION</h2>
                <div className="ai-gap--lg">
                  <div className="ai-gap--sm">
                    <p>
                      <span className="ai-text--italic">“MONSTECHCAT”</span> is an ambitious
                      large-scale project made as a showcase of the skills I’ve been developing for
                      6 years on my own.
                    </p>
                    <p>
                      With those skills, I’ve been able to create anything I wanted: Illustrations,
                      animations, merchandising, photography, video editing, sound design, sound
                      editing...
                    </p>
                    <p>
                      The aesthetic is fully driven by what defines my style the best: a sci-fi and
                      technologic theme in an organic environment; sharp and aggressive strokes, and
                      plain colors mixed with textures.
                    </p>
                  </div>
                  <div className="ai-gap--sm">
                    <p>This project was developed in 3 months on summer 2019.</p>
                    <p>As I worked on it non-stop for those months, I had to take a rest needed.</p>
                    <p>
                      After that, me and Ander (this web’s developer) decided to create a whole
                      website from scratch (as this project had so much content), so everything
                      would be there and clear for everyone to see it wrapped up. That’s why it took
                      some time to be released since I finished it.
                    </p>
                    <p>| Monstercat & RIOT logos used belong to them ©2020 |</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Works;
